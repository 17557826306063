import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import dynamic from 'next/dynamic';

const ParticipatedAssignmentContainerDynamic = dynamic(
  () => import('../../containers/participated-assignment/participated-assignment.container'),
);

const ResponderLayoutDynamic = dynamic(
  () => import('../../containers/responder-layout/responder.layout'),
  { ssr: false },
);

const ParticipatedAssignment = () => {
  return (
    <ResponderLayoutDynamic>
      <ParticipatedAssignmentContainerDynamic />
    </ResponderLayoutDynamic>
  );
};

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'assignment',
        'participated-assignment',
      ])),
    },
  };
};

export default ParticipatedAssignment;
